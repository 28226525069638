import add from "./components/addList.vue";
// import edit from "./components/edit.vue";
import approve from "./components/approve.vue";
export default {
    name: "profitRoll",
    data() {
        return {
            show: false,
            valid: true,
            headers: [
                {
                    text: "التاريخ الأولي",
                    value: "initialDate",
                },
                {
                    text: "تاريخ الموافقة",
                    value: "approvalDate",
                },

                {
                    text: "اسم المستخدم",
                    value: "user",
                },
                {
                    text: "مبلغ الاستثمار",
                    value: "userInvestment",
                },
                {
                    text: "الموافقة",
                    value: "approved",
                },
                {
                    text: "",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            profitRolls: [],
            itemsCompleted: [
                {
                    text: "بدون",
                    value: null,
                },
                {
                    text: "كاملة",
                    value: true,
                },
                {
                    text: "غير كاملة",
                    value: false,
                },
            ],
            itemsApproval: [
                {
                    text: "بدون",
                    value: null,
                },
                {
                    text: "موافق عليها",
                    value: true,
                },
                {
                    text: "غير موافق عليها",
                    value: false,
                },
            ],
            loading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            length: 0,
            filter: {
                pageIndex: 1,
                pageSize: 15,
                search: null,
                approved: null,
                due: null,
                year: null,
                month: null,
                day: null,
                count: 0,
            },
            validYear: true,
            validMonth: true,
            validDay: true,
            pageSizes: [5, 10, 15, 30, 50, 100],
        };
    },
    methods: {
        async resetFilter() {
            this.$refs.resetFilterParole.reset();
            await this.getProfitRoll(
                1,
                this.filter.year,
                null,
                null,
                null,
                null,
                null,
                null
            );
            this.valid = true;
            this.validDay = true;
            this.validYear = true;
            this.validMonth = true;
            await this.$refs.resetFilterParole.resetValidation();
        },
        async getProfitRoll(
            pageIndex,
            pageSize,
            search,
            approved,
            due,
            year,
            month,
            day
        ) {
            this.profitRolls = [];
            this.loading = true;
            let query = `/ProfitRoll?IsDelete=false`;
            if (pageIndex !== undefined && pageIndex !== null) {
                query = query + `&PageIndex=${pageIndex}`;
            }
            if (pageSize !== undefined && pageSize !== null) {
                query = query + `&PageSize=${pageSize}`;
            }
            if (search !== undefined && search !== null) {
                query += `&Search=${search}`;
            }
            if (approved !== undefined && approved !== null) {
                query += `&Approved=${approved}`;
            }
            if (due !== undefined && due !== null) {
                query += `&Due=${due}`;
            }
            if (year !== undefined && year !== null) {
                query += `&Year=${year}`;
            }
            if (month !== undefined && month !== null) {
                query += `&Month=${month}`;
            }
            if (day !== undefined && day !== null) {
                query += `&Day=${day}`;
            }

            await this.$http
                .get(query)
                .then((response) => {
                    const pros = response.data.result.data;
                    console.log(pros);
                    if (Array.isArray(pros)) {
                        // pros.forEach((pro) => {
                        //     pro.menu = false;
                        //     pro.loading = false;
                        // const index = this.profitRolls.findIndex(
                        //     (x) => x.id === pro.id
                        // );
                        // if (index !== -1) {
                        //     this.profitRolls.splice(index, 1);
                        // }
                        // console.log(pro, this.profitRolls);
                        // this.profitRolls.push(pro);
                        // });
                        this.profitRolls = pros;
                        this.filter.count = response.data.result.count;
                        this.length =
                            response.data.result.count === undefined
                                ? 0
                                : Math.ceil(
                                      response.data.result.count /
                                          this.filter.pageSize
                                  );
                    }
                })
                .catch((error) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${error.response.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async sortByMonth() {
            const currentDate = new Date();
            this.filter.year = currentDate.getFullYear();
            this.filter.month = currentDate.getMonth() + 1;
            this.filter.day = currentDate.getDate();
            await this.getProfitRoll(
                this.filter.pageIndex,
                this.filter.pageSize,
                this.filter.search,
                this.filter.approved,
                this.filter.due,
                this.filter.year,
                this.filter.month,
                null
            );
        },
        async sortByDay() {
            const currentDate = new Date();
            this.filter.year = currentDate.getFullYear();
            this.filter.month = currentDate.getMonth() + 1;
            this.filter.day = currentDate.getDate();
            await this.getProfitRoll(
                this.filter.pageIndex,
                this.filter.pageSize,
                this.filter.search,
                this.filter.approved,
                this.filter.due,
                this.filter.year,
                this.filter.month,
                this.filter.day
            );
        },
        validateYear() {
            const currentYear = new Date().getFullYear();
            const year = parseInt(this.filter.year, 10);
            const maxAllowedYear = currentYear + 5; // Five years from the current year
            this.validYear =
                !isNaN(year) &&
                year >= currentYear &&
                year <= maxAllowedYear &&
                year.toString().charAt(0) !== "0"; // Check if the year is between currentYear and maxAllowedYear and not starting with 0
            if (!this.validYear) {
                this.filter.month = null;
                this.filter.day = null;
            }
        },
        validateMonth() {
            const month = parseInt(this.filter.month, 10);
            this.validMonth =
                month >= 1 && month <= 12 && month.toString().charAt(0) !== "0"; // Check if month is between 1 and 12 and not starting with 0
            if (!this.validMonth) {
                this.filter.month = null;
                this.filter.day = null;
            }
        },
        validateDay() {
            const day = parseInt(this.filter.day, 10);
            const month = parseInt(this.filter.month, 10);
            const year = parseInt(this.filter.year, 10);
            const lastDayOfMonth = new Date(year, month, 0).getDate(); // Get the last day of the selected month and year

            this.validDay =
                day >= 1 &&
                day <= lastDayOfMonth &&
                day.toString().charAt(0) !== "0"; // Check if day is between 1 and the last day of the month and not starting with 0
        },
        formatInitialDate(initialDate) {
            const date = new Date(initialDate);
            const year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, "0");
            let day = date.getDate().toString().padStart(2, "0");

            return `${year}/${month}/${day}`;
        },
        async remove(profitRole) {
            let query = `/ProfitRoll?id=${profitRole.id}`;
            this.$genericService.swalAlertConfirm({}).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    profitRole.loading = true;
                    await this.$http
                        .delete(query)
                        .then(() => {
                            const index = this.profitRolls.findIndex(
                                (x) => x.id === profitRole.id
                            );
                            if (index !== -1) {
                                this.profitRolls.splice(index, 1);
                            }
                        })
                        .catch((error) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${
                                    error.response.data.message
                                        ? error.response.data.message
                                        : error.response.data.title
                                }`,
                                type: "error",
                            });
                        })
                        .finally(() => {
                            profitRole.loading = false;
                            this.loading = false;
                        });
                }
            });
        },
    },
    watch: {
        validYear(newValue) {
            if (!newValue) {
                this.filter.year = null;
            }
        },
        validMonth(newValue) {
            if (!newValue) {
                this.filter.month = null;
            }
        },
        validDay(newValue) {
            if (!newValue) {
                this.filter.day = null;
            }
        },
    },
    async created() {
        await this.getProfitRoll(
            1,
            this.filter.year,
            this.filter.search,
            null,
            null,
            null,
            null,
            null
        );
    },
    components: { add, approve },
};
