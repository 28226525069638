<script>
export default {
  name: "approve",
  data: () => ({
    requestDto: {
      id: null,
      receiverName: null,
      profit: 0,
    },
    rules: {
      required: [(val) => !!val || "هذا الحقل مطلوب"],
      numberOnly: [(val) => /^[0-9]+$/.test(val) || "يُسمح فقط بالأرقام"],
      lettersOnly: [(val) => /^[ء-ي\s]*$/.test(val) || "يُسمح فقط بالحروف"],
    },
    users: [],
    isfullscreen: false,
    valid: true,
    dialog: false,
    loading: false,
    activePicker: null,
    dateText: null,
    fullName: null,
    userId: null,
    menu: false,
  }),
  methods: {
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    formattedAmount(amount) {
      // Format the amount as IQD currency
      const formatter = new Intl.NumberFormat("ar-IQ", {
        style: "currency",
        currency: "IQD",
      });
      return formatter.format(amount);
    },
    whenOpen() {
      // this.requestDto.nextPayrollDate = this.oldDate;
      // this.save(this.formatInitialDate(this.oldDate));
      this.requestDto.id = this.id;
      this.fullName = this.user.fullName;
      this.userId = this.user.id;
      this.dialog = true;
      this.users.push(this.user);
    },
    save(date) {
      this.dateText = this.formatInitialDate(date);
      this.menu = false;
    },
    clearDate() {
      this.dateText = null;
    },

    isStringEmptyOrWhitespace(str) {
      // Check if the string is null, undefined, or contains only whitespace
      return !str || !str.trim();
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.$http
        .put(`${this.$route.meta.endPoint}/approve`, this.requestDto)
        .then((res) => {
          this.$emit(`refresh`);
          this.$store.dispatch("toastification/setToast", {
            message: `Done`,
            type: "success",
          });
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.dialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("toastification/setToast", {
            message: `${err.response.data.title}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    formatInitialDate(initialDate) {
      const date = new Date(initialDate);
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  props: ["user", "oldDate", "id"],
};
</script>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="50%"
      :fullscreen="isfullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator>
        <!-- <v-btn v-bind="attrs" v-on="on" color="primary" class="btn">
                    <v-icon small class="pl-1">mdi-plus</v-icon>
                    أضافة جديد
                </v-btn> -->
        <v-btn @click="whenOpen()" small width="100%" plain class="btn">
          <v-icon class="pl-1">mdi-tag-check-outline</v-icon>
        </v-btn>
      </template>
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="45" flat>
          <h5>الموافقة على التذكار</h5>
          <v-spacer />
          <v-btn small icon fab @click="isfullscreen = !isfullscreen">
            <v-icon small>{{
              isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand"
            }}</v-icon>
          </v-btn>
          <v-btn @click="dialog = false" fab small icon color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <v-col cols="6">
                  <h6 class="mb-1">
                    الربح (الأرقام فقط) <span class="required">*</span>
                  </h6>
                  <v-text-field
                    v-model="requestDto.profit"
                    label="الربح"
                    placeholder="أدخل الربح"
                    :hint="formattedAmount(requestDto.profit)"
                    persistent-hint
                    outlined
                    dense
                    clearable
                    type="number"
                    :rules="[rules.required, rules.numberOnly]"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <h6 class="mb-1">
                    اسم المستلم (الحروف فقط) <span class="required">*</span>
                  </h6>
                  <v-text-field
                    v-model="requestDto.receiverName"
                    label="اسم المستلم"
                    placeholder="أدخل اسم المستلم"
                    outlined
                    dense
                    :rules="[rules.required, rules.lettersOnly]"
                  ></v-text-field>
                </v-col>
                <!-- select user -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المستخدم <span class="required">*</span></h6>
                  <!--                                        :search-input.sync="filterUser.search"-->
                  <v-select
                    v-model="userId"
                    item-text="fullName"
                    item-value="id"
                    no-filter
                    readonly
                    dense
                    filled
                    placeholder="المستخدمين"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                    :items="users"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-divider class="ml- mt-8" />
              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn @click="close()" text depressed class="mr-2 btn">إلغاء</v-btn>
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.custom-font-size >>> .v-messages__message {
  font-size: 18px !important;
  color: black;
  /* Adjust the font size as needed */
}
</style>
